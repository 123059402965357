import api from "@/services/apiService"

class FileService {

  getFiles() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'file')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  uploadFile(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'file/upload', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  deleteFile(_id) {
    return new Promise((resolve, reject) => {
      api.delete(process.env.VUE_APP_BACKEND_API + 'file/' + _id)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }
}

export default new FileService();
