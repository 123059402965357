<template>
  <div class="manage-file">
    <div class="d-flex justify-content-between align-bottom mb-2">
      <strong class="text-primary">File Upload</strong>
      <button class="btn btn-sm btn-info" @click="selectFile()"><i class="fas fa-upload"></i> Upload</button>
    </div>
    <table class="table table-sm table-striped table-hover">
      <thead>
        <tr>
          <th>ไฟล์</th>
          <th>รายละเอียด</th>
          <th width="250" class="text-center">วันที่</th>
          <th width="60" class="text-center">ลบ</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item._id">
          <td>
            <a :href="item.fileUrl" target="_blank">{{ item.fileName }}</a>
          </td>
          <td width="40%">{{ item.fileDescription }}</td>
          <td class="text-center">{{ item.createdAt | uploadTime }}</td>
          <td class="text-center">
            <i class="fas fa-times delete-file text-danger" @click="deleteFile(item._id)"></i>
          </td>
        </tr>
        <tr v-if="!items.length">
          <td colspan="4" class="text-center">ไม่มีข้อมูล</td>
        </tr>
      </tbody>
    </table>

    <input ref="inputFile" type="file" accept="image/jpeg, image/png, image/gif" style="display: none;" v-on:change="handleFileUpload()">
    <ViewUploadFile :file="file" :is-show="isShowModal" @close="hideUploadModal" />
  </div>
</template>
<script>
import FileService from '@/services/fileService'
import ViewUploadFile from './components/ViewUploadFile'
import moment from '@/services/moment'
import Swal from 'sweetalert2'

export default {
  name: 'ManageUploadFiles',
  components: {
    ViewUploadFile
  },
  data() {
    return {
      file: null,
      isShowModal: false,
      data: null
    }
  },
  computed: {
    items() {
      return this.data || []
    }
  },
  methods: {
    loadFiles() {
      FileService.getFiles().then((response)=>{
        if(response.success) {
          this.data = response.data
        }
      })
    },
    selectFile() {
      this.$refs.inputFile.click()
    },
    handleFileUpload() {
      const file = this.$refs.inputFile.files[0]
      if(file) {
        this.file = file
        this.isShowModal = true

        // รีเซ็ตค่า input เพื่อให้สามารถเลือกไฟล์เดิมได้อีกครั้ง
        this.$refs.inputFile.value = ''
      }
    },
    hideUploadModal(needReload) {
      this.isShowModal = false
      this.file = null
      if(needReload) {
        this.loadFiles()
      }
    },
    deleteFile(_id) {
      Swal.fire({
        title: 'ต้องการลบไฟล์?',
        icon: 'warning',
        confirmButtonText: 'ยืนยัน',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก'
      })
      .then((res)=>{
        if(res.isConfirmed) {
          FileService.deleteFile(_id)
          .then((response)=>{
            if(response.success) {
              this.$notify({
                type: 'success',
                title: 'สำเร็จ!',
                text: 'ลบไฟล์เสร็จเรียบร้อย',
              })
            }else{
              throw new Error()
            }
          })
          .catch((e)=>{
            Swal.fire({
              title: 'ผิดพลาด!',
              text: 'ลบไฟล์ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
          .finally(()=>{
            this.loadFiles()
          })
        }
      })
    }
  },
  mounted() {
    this.loadFiles()
  },
  filters: {
    uploadTime(data) {
      return moment(data).format("YYYY-MM-DD HH:mm:ss")
    }
  }
}
</script>
<style lang="scss" scoped>
.manage-file {
  .table {
    tbody {
      tr {
        td {
          vertical-align: middle;

          .delete-file {
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
